import React from 'react'
import { Box, Text } from '../lib/design-system'
import pad from '../lib/pad'

export default ({ value, ...props }) => (
  <Box
    borderBottom="1px solid"
    borderColor="blue"
    pb={2}
    css={{ display: 'inline-block' }}
    {...props}
  >
    <Text
      color="blue"
      css={{
        fontFeatureSettings: "'calt' 1, 'zero' 1",
      }}
    >
      {pad(value, 2)}
    </Text>
  </Box>
)
