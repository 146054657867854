import React from 'react'
import { Flex, Box } from '../lib/design-system'
import Caps from './Caps'
import AnchorLink from './AnchorLink'
import { ScrollWatch } from './ScrollSpy'

const NavLink = ({ children, href, color, atTop, ...props }) => {
  const id = href.substr(1)
  return (
    <ScrollWatch id={id}>
      {({ isActive }) => (
        <AnchorLink
          href={href}
          render={linkProps => (
            <Box
              {...linkProps}
              as="a"
              href={href}
              px={[3, 3, '28px']}
              display="block"
              {...props}
              color={atTop ? 'white' : isActive ? 'blue' : 'black'}
              css={{
                textDecoration: 'none',
                transition: 'color 0.1s',
              }}
            >
              <Caps fontSize={1} fontWeight={700}>
                {children}
              </Caps>
            </Box>
          )}
        />
      )}
    </ScrollWatch>
  )
}

class Nav extends React.Component {
  state = { variant: 'light' }
  componentDidMount() {
    this.banner = document.getElementById('banner')
    this.nav = document.getElementById('nav')
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.handleScroll()
  }

  handleResize = () => {
    this.bannerHeight = this.banner.offsetHeight
    this.navHeight = this.nav.offsetHeight
  }

  handleScroll = () => {
    const scrollY = window.pageYOffset
    const variant =
      scrollY > this.bannerHeight - this.navHeight / 2 ? 'dark' : 'light'
    if (this.state.variant !== variant) {
      this.setState({ variant })
    }
  }

  render() {
    return (
      <Box
        id="nav"
        position="fixed"
        display={['none', 'block']}
        top={0}
        right={0}
        p={[3, 4, 5]}
        zIndex={9}
      >
        <Flex mx={-3} height="40px" alignItems="center">
          <NavLink atTop={this.state.variant === 'light'} href="#cabinet">
            Le cabinet
          </NavLink>
          <NavLink atTop={this.state.variant === 'light'} href="#equipe">
            L'équipe
          </NavLink>
          <NavLink atTop={this.state.variant === 'light'} href="#contact">
            Contact
          </NavLink>
        </Flex>
      </Box>
    )
  }
}

export default Nav
