import { Flex } from '../lib/design-system'
import React from 'react'

const Circle = ({ diameter, css, ...props }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    css={{ borderRadius: '999px', ...css }}
    width={diameter}
    height={diameter}
    {...props}
  />
)

Circle.defaultProps = {
  diameter: 32,
  color: 'white',
  bg: '#EFB3B1',
}

export default Circle
