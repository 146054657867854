import React from 'react'
import { Box } from '../lib/design-system'
import feather from 'feather-icons'
import theme from '../themes'

const Icon = ({ name, color, size, ...props }) => (
  <Box
    {...props}
    dangerouslySetInnerHTML={{
      __html: feather.icons[name].toSvg({
        stroke: theme.colors[color] || color,
        width: size,
        height: size,
        'stroke-width': 2,
      }),
    }}
    css={{
      '> svg': {
        display: 'block',
      },
    }}
  />
)

Icon.defaultProps = {
  name: 'x',
  size: 20,
}

export default Icon
