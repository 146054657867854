const blue = '#0034FF'
const silver = '#F7F7F7'
const black = '#111111'
const white = '#FFFFFF'
const gray = '#757575'

const colors = { white, black, blue, silver, gray }

const theme = { colors }

export default theme
