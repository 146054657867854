import { createGlobalStyle } from 'styled-components'
import InterUIRegular from '../fonts/Inter-UI/Inter-UI-Regular.woff2'
import InterUIBold from '../fonts/Inter-UI/Inter-UI-Bold.woff2'
import LibreBaskervilleRegular from '../fonts/Libre-Baskerville/LibreBaskerville-Regular.woff2'

export default createGlobalStyle`
    html {
      background: #FFFFFF;
    }
    
    body {
      margin: 0;
      font-family: 'Inter UI',  sans-serif;
    }

    *  {
      box-sizing: border-box;
    }

    @font-face {
      font-family: 'Inter UI';
      font-style:  normal;
      font-weight: 400;
      src: url(${InterUIRegular});
    }

    @font-face {
      font-family: 'Inter UI';
      font-style:  normal;
      font-weight: 700;
      src: url(${InterUIBold});
    }

    @font-face {
      font-family: 'Libre Baskerville';
      font-style:  normal;
      font-weight: 400;
      src: url(${LibreBaskervilleRegular});
    }
   
`
