import React from 'react'

class AnchorLink extends React.Component {
  handleClick = evt => {
    evt.preventDefault()
    const { href } = this.props
    const id = href.substr(1)
    const el = document.getElementById(id)
    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  render() {
    const { href, render } = this.props
    return render({ onClick: this.handleClick, href })
  }
}

export default AnchorLink
