import React from 'react'
import scrollMonitor from 'scrollmonitor'

const ScrollSpyContext = React.createContext({})

export class ScrollSpy extends React.Component {
  constructor(props) {
    super(props)
    this.watchers = {}

    this.register = (id, el) => {
      if (this.watchers[id]) return
      const watcher = scrollMonitor.create(el, { top: window.innerHeight / 2 })
      watcher.stateChange(() => {
        if (watcher.isAboveViewport && watcher.isInViewport) {
          this.setActive(id)
        }
      })
      this.watchers[id] = watcher
    }

    this.setActive = id => {
      if (id !== this.state.activeId) {
        this.setState({
          activeId: id,
        })
      }
    }

    this.state = {
      activeId: 'cabinet',
      register: this.register,
    }
  }

  render() {
    const { children } = this.props
    return (
      <ScrollSpyContext.Provider value={this.state}>
        {children}
      </ScrollSpyContext.Provider>
    )
  }
}

export const ScrollWatch = ({ id, children }) => (
  <ScrollSpyContext.Consumer>
    {({ activeId, register }) => {
      return children({ isActive: activeId === id, register })
    }}
  </ScrollSpyContext.Consumer>
)
