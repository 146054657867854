export default (value, size) => {
  var sign = Math.sign(value) === -1 ? '-' : ''
  return (
    sign +
    new Array(size)
      .concat([Math.abs(value)])
      .join('0')
      .slice(-size)
  )
}
