import React from 'react'
import Layout from '../components/Layout'
import { Flex, Box, Heading, Text, Image } from '../lib/design-system'
import Container from '../components/Container'
import Caps from '../components/Caps'
import Number from '../components/Number'
import bgImage from '../images/bg-paris.jpg'
import bgVideo from '../videos/video-fgpg.mp4'
import posterVideo from '../images/poster-fgpg.jpg'
import theme from '../themes'
import Circle from '../components/Circle'
import Icon from '../components/Icon'
import smoothscroll from 'smoothscroll-polyfill'
import imagePierreAlainGuillermain from '../images/avatar-pierre-alain-guillermin.png'
import imageFlorenceGauchetAvril from '../images/avatar-florence-gauchet-avril.png'
import AnchorLink from '../components/AnchorLink'
import { ScrollSpy, ScrollWatch } from '../components/ScrollSpy'

const getMaboxTileImageURL = (
  lat = 0,
  lng = 0,
  zoom = 14,
  width = 100,
  height = 100,
  token = 'pk.eyJ1IjoiZWZmbGFtIiwiYSI6ImNqbmV4Z25sMDFudHMzcHA4aGMyMGNoeXIifQ.Z_caFj87UNZwaYFyS_oOYA'
) =>
  `https://api.mapbox.com/styles/v1/efflam/cjnexdxqt1r7b2sntl41mz0br/static/${lat},${lng},${zoom},0.00,0.00/${Math.round(
    width
  )}x${Math.round(height)}@2x?access_token=${token}`

const data = {
  company: {
    description:
      "FGPG Avocats accompagne les entreprises lors de leur création ainsi qu'à chaque étape de leur vie sociale, répondant ainsi à leurs besoins en termes de développement ou d'adaptation aux divers événements de la vie économique.",
    products: [
      {
        title: 'Droit des sociétés',
        description:
          "FGPG Avocats accompagne les entreprises lors de leur création ainsi qu'à chaque étape de leur vie sociale, répondant ainsi à leurs besoins en termes de développement ou d'adaptation aux divers événements de la vie économique.",
        exemples: [
          'Création de sociétés',
          'Assemblées générales',
          'Augmentations de capital',
          'Fusion/acquisition',
          'Scission',
          "Apports partiels d'actifs",
          'Cession de titres',
          'Dissolution/liquidation',
          "Pactes d'associés et actionnaires",
          'Statut des dirigeants et mandataires sociaux',
        ],
      },
      {
        title: 'Droit social',
        description:
          "FGPG Avocats assiste ses clients au quotidien, ainsi qu'en phase pré-contentieuse et jusque devant les juridictions prud'homales et la Cour d'appel.",
        exemples: [
          "Contentieux devant le Conseil de Prud'hommes et la Cour d'appel",
          'Contrat de travail',
          'Mise en place et gestion des instances représentatives du personnel',
          'Départs négociés',
          'Sanctions disciplinaires',
          'Licenciements pour motifs personnels',
          'Licenciements économiques',
          'Harcèlement',
          'Discrimination',
          'Audit social',
          'Intéressement des dirigeants et salariés',
        ],
      },
      {
        title: 'Droit commercial',
        description:
          "FGPG Avocats dispose d'une solide expérience en matière de cession de fonds de commerce, de baux commerciaux et de contentieux commercial.",
        exemples: [
          'Cessions de fonds de commerce',
          'Baux commerciaux',
          'Cessions de droit au bail',
          'Contentieux commercial',
        ],
      },
      {
        title: 'Droit des marques',
        description:
          'FGPG Avocats assure le dépôt et le suivi des marques de ses clients sur le plan national, européen, et pour le reste du monde.',
        exemples: [
          "Dépôts de marques auprès de l'office national (INPI), européen (EUIPO) et mondial (OMPI)",
          'Suivi du portefeuille de marque',
        ],
      },
    ],
  },
}

class Banner extends React.Component {
  state = {
    height: '100vh',
  }

  componentDidMount() {
    this.setState({ height: window.innerHeight })
  }

  render() {
    const { children } = this.props
    const { height } = this.state
    return (
      <Box
        position="relative"
        id="banner"
        overflow="hidden"
        mt={['-66px', 0]}
        pt={['66px', 0]}
        style={{ height }}
      >
        <video
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: '80% center',
          }}
          src={bgVideo}
          autoPlay
          preload="auto"
          type="video/mp4"
          loop
          playsInline
          muted
          poster={posterVideo}
        />
        <Flex
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          justifyContent="center"
          alignItems="center"
          // pb={['66px', 0]}
        >
          {children}
        </Flex>
        <Flex
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          justifyContent="center"
          alignItems="center"
          pb={[5, 5]}
        >
          <AnchorLink
            href="#cabinet"
            render={linkProps => (
              <Circle
                as="a"
                {...linkProps}
                bg="transparent"
                diameter={48}
                css={{
                  cursor: 'pointer',
                  transition: 'background-color 0.2s',
                  '-webkit-tap-highlight-color': 'transparent',
                  svg: { transition: 'stroke 0.2s' },
                  ':hover': {
                    backgroundColor: 'white',
                    svg: { stroke: 'blue' },
                  },
                }}
                border="1px solid"
                borderColor="rgba(255, 255, 255, 0.3)"
              >
                <Icon name="arrow-down" color="white" />
              </Circle>
            )}
          />
        </Flex>
      </Box>
    )
  }
}
const Section = ({ children, id, ...props }) => (
  <ScrollWatch id={id}>
    {({ isActive, register }) => (
      <Box
        as="section"
        py={[5, 6]}
        ref={view => register(id, view)}
        id={id}
        {...props}
      >
        <Container>{children}</Container>
      </Box>
    )}
  </ScrollWatch>
)

const SectionTitle = props => (
  <Heading
    fontSize={[5, 6]}
    as="h2"
    fontFamily="Libre Baskerville"
    fontWeight={400}
    mb={3}
    {...props}
  />
)

const SectionSubtitle = props => <Caps fontSize={0} {...props} />

const SectionHeader = ({ title, subtitle }) => (
  <Box mb={4} pb={4} position="relative">
    <SectionTitle>{title}</SectionTitle>
    <SectionSubtitle>{subtitle}</SectionSubtitle>
    <Box position="absolute" bottom="0" width="100px" height="3px" bg="blue" />
  </Box>
)

const SectionIntro = ({ children, ...props }) => (
  <Box {...props} mb={5} width={[1, 1, 7 / 12]}>
    <Text>{children}</Text>
  </Box>
)

const Chapter = props => <Box mb={4} {...props} />

const ChapterHeader = ({ number, children, ...props }) => (
  <Box mb={3} {...props}>
    <Number value={number} mb={3} />
    <Heading
      as="h3"
      fontSize={4}
      fontFamily="Libre Baskerville"
      fontWeight={400}
    >
      {children}
    </Heading>
  </Box>
)

const Link = ({ children, ...props }) => (
  <Text
    as="a"
    borderBottom="1px solid"
    borderColor="blue"
    pb="2px"
    color="black"
    css={{
      textDecoration: 'none',
      cursor: 'pointer',
      display: 'inline-block',
      transition: 'color 0.2s',
      ':hover': { color: theme.colors.blue },
    }}
    {...props}
  >
    {children}
  </Text>
)

const List = ({ items }) => (
  <Box>
    {items.map((item, index) => (
      <Flex key={index} mb={index < items.length - 1 ? 2 : 0}>
        <Box width="10px" height="1px" bg="blue" mr={3} mt="9px" />
        <Box flex={1}>
          <Text fontFamily="Libre Baskerville" fontSize={1} color="gray">
            {item}
          </Text>
        </Box>
      </Flex>
    ))}
  </Box>
)

class IndexPage extends React.Component {
  componentDidMount() {
    smoothscroll.polyfill()
  }

  render() {
    return (
      <ScrollSpy>
        <Layout>
          <Box position="absolute" top={0} left={0} zIndex={9} p={[4, 4, 5]}>
            <Text fontSize={['24px', '32px']} fontWeight={700} color="white">
              FGPG
            </Text>
          </Box>
          <Banner src={bgImage}>
            <Heading
              as="h1"
              fontSize={[4, 5, 6, 7]}
              color="white"
              fontWeight={500}
              fontFamily="Libre Baskerville"
            >
              Avocats au barreau de Paris
            </Heading>
          </Banner>
          <Box id="content" pb={['66px', 0]}>
            <Section bg="white" id="cabinet">
              <SectionHeader
                title="Le cabinet"
                subtitle="Un service juridique complet"
              />
              <SectionIntro>
                FGPG Avocats exerce son activité auprès des entreprises et des
                particuliers qu'il accompagne tant en matière de conseil que
                lors des procédures judiciaires
              </SectionIntro>
              <Box width={1} overflow="hidden">
                <Flex mx={-5} flexWrap="wrap">
                  {data.company.products.map((product, index) => (
                    <Chapter
                      key={index}
                      width={[1, 0.5]}
                      px={5}
                      pt={[0, 0, index % 2 === 0 ? 0 : 6]}
                      mb={[
                        index < data.company.products.length - 1 ? 5 : 0,
                        index < data.company.products.length - 1 ? 5 : 0,
                        0,
                      ]}
                    >
                      <ChapterHeader number={index + 1}>
                        {product.title}
                      </ChapterHeader>
                      <Box mb={4}>
                        <Text>{product.description}</Text>
                      </Box>
                      <Box pl={4} width={1}>
                        <Box
                          pl={3}
                          borderLeft="1px solid"
                          borderColor="blue"
                          width={1}
                        >
                          <Text
                            color="black"
                            mb={3}
                            fontFamily="Libre Baskerville"
                          >
                            Expertises :
                          </Text>
                          <List items={product.exemples} />
                        </Box>
                      </Box>
                    </Chapter>
                  ))}
                </Flex>
              </Box>
            </Section>
            <Section bg="silver" id="equipe">
              <SectionHeader
                title="L'équipe"
                subtitle="Votre succès est aussi le notre"
              />
              <SectionIntro>
                Le cabinet FGPG Avocats a été fondé suite à l'association de
                Maître Florence Gauchet-Avril et de Maître Pierre-Alain
                Guillermin après plusieurs années de collaboration.
              </SectionIntro>
              <Box width={1}>
                <Flex mx={-4} flexDirection="row" flexWrap="wrap">
                  <Chapter width={[1, 0.5]} px={4} mb={[5, 0]}>
                    <Image
                      src={imageFlorenceGauchetAvril}
                      mb={4}
                      width={2 / 3}
                    />
                    <ChapterHeader mb={4} number={1}>
                      Florence
                      <br />
                      Gauchet-Avril
                    </ChapterHeader>
                    <Box mb={3}>
                      <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                        Diplômes :
                      </Heading>
                      <Text>
                        DEA Droit social,
                        <br />
                        Université Bordeaux
                      </Text>
                    </Box>
                    <Box mb={3}>
                      <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                        Langues :
                      </Heading>
                      <Text>Français</Text>
                    </Box>
                    <Box mb={3}>
                      <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                        Domaines d'activité principaux :
                      </Heading>
                      <Text>Droit des sociétés</Text>
                      <Text>
                        Cessions de fonds de commerce et de droit au bail
                      </Text>
                      <Text>Droit social</Text>
                      <Text>Baux commerciaux</Text>
                    </Box>
                    <Box mb={3}>
                      <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                        Contact :
                      </Heading>
                      <Link href="mailto:fgauchet-avril@fgpg-avocats.fr">
                        fgauchet-avril@fgpg-avocats.fr
                      </Link>
                    </Box>
                  </Chapter>
                  <Chapter width={[1, 0.5]} px={4}>
                    <Image
                      src={imagePierreAlainGuillermain}
                      mb={4}
                      width={2 / 3}
                    />
                    <ChapterHeader mb={4} number={2}>
                      Pierre-Alain
                      <br />
                      Guillermin
                    </ChapterHeader>
                    <Box mb={3}>
                      <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                        Diplômes :
                      </Heading>
                      <Text>
                        Master Droit de l’entreprise et des affaires,<br/>spécialité Droit du marché,
                        <br />
                        Université Montpellier I
                      </Text>
                    </Box>
                    <Box mb={3}>
                      <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                        Langues :
                      </Heading>
                      <Text>Français, Anglais</Text>
                    </Box>
                    <Box mb={3}>
                      <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                        Domaines d'activité principaux :
                      </Heading>
                      <Text>Droit social</Text>
                      <Text>Droit des sociétés</Text>
                      <Text>Contentieux commercial</Text>
                      <Text>Droit des marques</Text>
                    </Box>
                    <Box mb={3}>
                      <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                        Contact :
                      </Heading>
                      <Link href="mailto:paguillermin@fgpg-avocats.fr">
                        paguillermin@fgpg-avocats.fr
                      </Link>
                    </Box>
                  </Chapter>
                </Flex>
              </Box>
            </Section>
            <Section bg="white" id="contact">
              <SectionHeader
                title="Contact"
                subtitle="A l'écoute de vos demandes"
              />
              <SectionIntro>
                Le cabinet FGPG Avocats est à l’écoute de tous vos projets.
              </SectionIntro>
              <Flex
                mx={-4}
                flexWrap="wrap"
                css={{
                  alignContent: 'stretch',
                  alignItems: 'stretch',
                }}
              >
                <Box
                  mb={[5, 5, 0]}
                  px={4}
                  height="100%"
                  alignItems="center"
                  width={[1, 1, 1 / 3]}
                >
                  <Box mb={[3, 4]}>
                    <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                      Adresse :
                    </Heading>
                    <Text>
                      FGPG Avocats
                      <br />
                      105 rue Lafayette
                      <br />
                      75010 Paris
                      <br />
                      France
                    </Text>
                  </Box>
                  <Box mb={[3, 4]}>
                    <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                      Mail :
                    </Heading>
                    <Link href="mailto:contact@fgpg-avocats.fr">
                      contact@fgpg-avocats.fr
                    </Link>
                  </Box>
                  <Box mb={[3, 4]}>
                    <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                      Téléphone :
                    </Heading>
                    <Link href="tel:+33964166781">09 64 16 67 81</Link>
                  </Box>
                  <Box mb={[3, 4]}>
                    <Heading as="h4" fontWeight={700} fontSize={2} mb={2}>
                      Fax :
                    </Heading>
                    <Link href="tel:+33145267377">01 45 26 73 77</Link>
                  </Box>
                </Box>
                <Box px={4} width={[1, 1, 2 / 3]}>
                  <Box position="relative" width="100%">
                    <Image
                      src={getMaboxTileImageURL(
                        2.3484754,
                        48.8777483,
                        12,
                        600,
                        600 * (1 / 1.6)
                      )}
                      width="100%"
                      css={{ maxWidth: '100%' }}
                    />
                    <Flex
                      position="absolute"
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box>
                        <svg
                          style={{ width: 32, height: 32 }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill={theme.colors.blue}
                            d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                          />
                        </svg>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Flex>
            </Section>
          </Box>
        </Layout>
      </ScrollSpy>
    )
  }
}

export default IndexPage
