import React from 'react'
import { Flex, Box } from '../lib/design-system'
import Caps from './Caps'
import Icon from '../components/Icon'
import AnchorLink from './AnchorLink'
import { ScrollWatch } from './ScrollSpy'

const MobileNavLink = ({ children, iconName, href, color, ...props }) => {
  const id = href.substr(1)
  return (
    <ScrollWatch id={id}>
      {({ isActive }) => (
        <AnchorLink
          href={href}
          render={linkProps => (
            <Flex
              as="a"
              flex={1}
              py={3}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              color={isActive ? 'blue' : 'black'}
              css={{
                textDecoration: 'none',
                '-webkit-tap-highlight-color': 'transparent',
              }}
              {...linkProps}
              {...props}
            >
              <Icon
                name={iconName}
                mb="6px"
                color={isActive ? 'blue' : 'black'}
                size={16}
              />
              <Caps fontSize={'10px'} fontWeight={700}>
                {children}
              </Caps>
            </Flex>
          )}
        />
      )}
    </ScrollWatch>
  )
}

export default () => (
  <Box
    bg="white"
    borderTop="1px solid"
    borderColor="silver"
    display={['flex', 'none']}
    position="fixed"
    bottom={0}
    left={0}
    width={1}
    zIndex={9}
    justifyContent="space-between"
    alignItems="center"
  >
    <MobileNavLink iconName="home" href="#cabinet">
      cabinet
    </MobileNavLink>
    <MobileNavLink iconName="users" href="#equipe">
      équipe
    </MobileNavLink>
    <MobileNavLink iconName="mail" href="#contact">
      Contact
    </MobileNavLink>
  </Box>
)
