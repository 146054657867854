import React from 'react'
import { Box } from '../lib/design-system'

export default props => (
  <Box
    {...props}
    px={[4, 5]}
    width="100%"
    mx="auto"
    css={{ maxWidth: '1080px' }}
  />
)
